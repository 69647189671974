import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

// import logo from "./logo.svg";
import "./App.css";

const DefaultLayout = React.lazy(() => import("./Layout/DefaultLayout"));
class App extends Component {
  loading = () => (
    <div className="preloader">
      <div className="loader">
        <div className="loader-outter"></div>
        <div className="loader-inner"></div>
        <div className="indicator">
          <svg width="16px" height="12px">
            <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
            <polyline
              id="front"
              points="1 6 4 6 6 11 10 1 12 6 15 6"
            ></polyline>
          </svg>
        </div>
      </div>
    </div>
  );
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={this.loading()}>
          <Switch>
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
